<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="salvar">
    <v-row>
      <v-col cols="12" sm="8" md="8" lg="8">
        <v-text-field
          label="E-mail"
          outlined
          dense
          v-model="form.email"
          :rules="[rules.required, rules.email]"
          autocomplete="false"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4">
        <v-text-field
          label="Senha"
          outlined
          dense
          v-model="form.senha"
          :rules="[rules.required]"
          autocomplete="new-password"
          type="password"
        ></v-text-field>
      </v-col>
      <v-col cols="12" align="right">
        <v-btn
          dark
          depressed
          color="primary"
          type="submit"
          :loading="loading"
          :disabled="loading"
          >Salvar</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Usuario from "@/service/usuario";
export default {
  name: "FormUsuario",
  props: {
    payload: {
      type: Object,
    },
  },
  data() {
    return {
      valid: true,
      loading: false,
      form: {
        email: "",
        senha: "",
      },
      rules: {
        required: (value) => !!value || "Obrigatório",
        email: (value) => {
          if (!value) return "";
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "e-mail inválido.";
        },
      },
    };
  },

  methods: {
    async salvar() {
      if (!this.$refs.form.validate()) return;
      try {
        const payload = {
          usuario: {
            ...this.form,
            email: this.form.email.toLowerCase(),
            verificado: true,
            bloqueado: false,
            perfis: [{ id: 2 }],
          },
          clienteId: this.payload.id,
        };
        await Usuario.save(payload);

        this.$emit("sucesso");
        this.$notify({
          title: "Sucesso",
          text: "Dados salvos!",
          type: "success",
        });
      } catch (error) {
        let msg = "Ocorreu um erro ao salvar os dados!";
        if (error.title && error.title.includes("Violação"))
          msg = "E-mail já cadastrado.";

        this.$notify({
          title: "Erro",
          text: msg,
          type: "error",
        });
        console.log(error);
      }
    },
  },
  created() {
    console.log(this.payload);
    if (this.payload && this.payload.usuario) {
      this.form = { ...this.payload.usuario };
    }
  },
};
</script>

<style scoped>
div input {
  text-transform: uppercase;
}
</style>
