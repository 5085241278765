<template>
  <div>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="usuarios"
        :loading="loading"
        :options.sync="options"
        :server-items-length="pageable.totalElements"
        hide-default-footer
        dense
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title> Usuários</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn color="primary" dark class="square" @click="abreForm"
              ><v-icon>{{ mdiPlus }}</v-icon></v-btn
            > -->
          </v-toolbar>
        </template>
        <template v-slot:[`item.block`]="{ item }">
          <v-chip
            :color="
              item.usuario && item.usuario.bloqueado
                ? 'success darken-2'
                : 'error darken-2'
            "
            dark
            x-small
          >
            <strong>{{
              item.usuario && item.usuario.bloqueado ? "Sim" : "Não"
            }}</strong>
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="primary darken-2"
            @click="editar(item)"
            title="Editar"
          >
            <v-icon v-if="item.usuario">{{ mdiPencilBox }}</v-icon>
            <v-icon v-else>{{ mdiPlusBox }}</v-icon>
          </v-btn>
          <!-- <v-btn
            icon
            color="error darken-2"
            @click="excluir(item)"
            title="Excluir"
          >
            <v-icon>{{ mdiCloseBox }}</v-icon>
          </v-btn> -->
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="pageable.page"
          :length="pageable.totalPages"
          @input="changePage"
        ></v-pagination>
      </div>
    </v-card>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="800px"
      :fullscreen="mobile"
    >
      <v-card>
        <v-card-title
          >Usuário
          <v-spacer></v-spacer>

          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <UsuarioForm
            v-if="dialog"
            @sucesso="inicializar"
            :payload="payload"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cliente from "@/service/cliente";
import Usuario from "@/service/usuario";
import UsuarioForm from "./Form";
import {
  mdiCloseBox,
  mdiPencilBox,
  mdiPlusBox,
  mdiFileDocumentEditOutline,
} from "@mdi/js";
export default {
  name: "Usuario",
  components: {
    UsuarioForm,
  },
  data() {
    return {
      mdiCloseBox,
      mdiPencilBox,
      mdiPlusBox,
      mdiFileDocumentEditOutline,
      loading: false,
      usuarios: [],
      options: {},
      pageable: {},
      dialog: false,
      payload: {},
      headers: [
        {
          text: "Nome",
          value: "nome",
          align: "start",
          filterable: false,
          sortable: false,
        },
        {
          text: "Email",
          value: "usuario.email",
          filterable: false,
          sortable: false,
        },
        {
          text: "Bloqueado",
          value: "block",
          filterabldatae: false,
          sortable: false,
        },
        {
          text: "Ações",
          value: "actions",
          filterabldatae: false,
          sortable: false,
        },
      ],
    };
  },

  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    changePage(value) {
      this.pageable.page = value;
      this.inicializar({ page: value - 1 });
    },
    abreForm() {
      this.payload = {};
      this.dialog = true;
    },
    async inicializar(options) {
      this.loading = true;
      this.dialog = false;

      const resp = await Cliente.findAll({ ...options });
      this.usuarios = resp.content;
      this.pageable = {
        totalElements: resp.totalElements,
        totalPages: resp.totalPages,
        pageSize: resp.size,
        page: resp.number + 1,
      };
      this.loading = false;
    },
    editar(item) {
      this.payload = item;
      this.$nextTick(() => (this.dialog = true));
    },
    async excluir(item) {
      try {
        await Usuario.delete(item);
        this.$notify({
          title: "Sucesso",
          text: "Dados salvos!",
          type: "success",
        });
        await this.inicializar();
      } catch (error) {
        this.$notify({
          title: "Erro",
          text: "Ocorreu um erro ao excluir o usuário",
          type: "error",
        });
        console.log(error);
      }
    },
  },
  created() {
    this.inicializar();
  },
};
</script>

<style></style>
