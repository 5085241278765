import http from "./http"

const Cliente = {
  findAll: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `clientes`,
        params: payload
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })

    })
  },

  findAllList: async () => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `clientes/list`,

      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })

    })
  },

  findById: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `clientes?id=${payload.id}`,
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })
    })
  },

  save: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "POST",
        url: `clientes`,
        data: payload
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })

    })
  },
  delete: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "DELETE",
        url: `clientes`,
        data: { ...payload }
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })

    })
  }
}

export default Cliente;